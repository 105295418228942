import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { CircularLoader } from "..";
import { useAppContext } from "src/hooks";
import ReactEditor from "react-text-editor-kit";

let editorImages = [];
export default function RichEditor({
  editorRef = null,
  value = "",
  setValue = () => {},
  disabled = false,
  onBlur = () => {},
  placeHolder = "",
}) {
  const { keys, keyLoading } = useAppContext();
  const [isReady, setIsReady] = useState(false);
  const [focuse, setfocuse] = useState(false);
  const [initialValue, setInitialValue] = useState(value);
  // const [value, setValue] = useState("");
  const ref = useRef(null);

  const handleInit = (evt, editor) => {
    console.log("editor-ready");
    if (editorRef) {
      editorRef.current = editor;
    }
    setIsReady(true);
  };
  const handleOutsideClick = (event) => {
    // Check if the click is outside the TinyMCE editor toolbar
    const button = document.getElementsByClassName(
      "tox-tbtn tox-tbtn--enabled"
    )[0];
    const toolbar = document.getElementsByClassName("tox-toolbar__overflow")[0];
    if (
      toolbar &&
      !toolbar.contains(event.target) &&
      !button?.contains(event.target)
    ) {
      button.click();
    }
  };
  const handleUpdateImageWidth = (result, index) => {
    let width = result[index].image.getAttribute("width");
    let height = result[index].image.getAttribute("height");

    if (width) {
      if (width > 200) {
        height = height / (width / 100);
        result[index].image.style.height = `${height}px`;
        result[index].image.style.width = "100px";
      }
    } else {
      result[index].image.style.width = "100px";
    }
  };
  function handleEditorInit() {
    setTimeout(() => {
      let containerWarning = document.getElementsByClassName(
        "tox-notification--warning"
      );
      if (containerWarning.length > 0) {
        containerWarning[0].style.display = "none";
        setfocuse(false);
      }
      setfocuse(false);
    }, 100);
  }
  useEffect(() => {
    handleEditorInit();
  }, [focuse]);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    setValue(initialValue ?? "");
    return () => setInitialValue("");
  }, [initialValue]);
  return (
    <div style={{ position: "relative" }}>
      {/* {!keyLoading && ( */}
      <ReactEditor
        // apiKey={keys.admin}
        onInit={(evt, editor) => {
          handleInit(evt, editor);
        }}
        // initialValue={initialValue}
        value={value}
        onChange={setValue}
        disabled={disabled}
        autoFocus={true}
        onBlur={onBlur}
        onClick={handleOutsideClick}
        // onEditorChange={(newValue, editor) => {
        //   setValue(newValue);
        //   setfocuse(true);
        // }}
        // init={{
        //   height: 253,
        //   menubar: true,
        //   image_caption: true,
        //   resize: false,
        //   statusbar: false,
        //   // max_height: 600,
        //   min_height: 350,
        //   // width:auto,
        //   // images_file_types: "jpg",
        //   selector: "textarea",
        //   placeholder: placeHolder,
        //   // images_upload_handler: image_upload_handler,
        //   plugins: [
        //     "advlist",
        //     "autolink",
        //     "lists",
        //     "link",
        //     "image",
        //     "charmap",
        //     "preview",
        //     "anchor",
        //     "searchreplace",
        //     "visualblocks",
        //     "code",
        //     "fullscreen",
        //     "insertdatetime",
        //     "media",
        //     "table",
        //     "code",
        //     "autoresize",
        //   ],
        //   toolbar:
        //     "blocks |" +
        //     "bold italic underline | forecolor backcolor |" +
        //     "strikethrough link image |" +
        //     "bullist numlist |undo redo |",
        //   content_style:
        //     "body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img {max-height:156px; max-width:326px; object-fit:contain}",

        //   //
        //   file_picker_callback: (cb, value, meta) => {
        //     const input = document.createElement("input");
        //     input.setAttribute("type", "file");
        //     input.setAttribute("accept", "image/*");
        //     input.addEventListener("change", (e) => {
        //       const file = e.target.files[0];

        //       const reader = new FileReader();
        //       reader.addEventListener("load", () => {
        //         /*
        //                 Note: Now we need to register the blob in TinyMCEs image blob
        //                 registry. In the next release this part hopefully won't be
        //                 necessary, as we are looking to handle it internally.
        //               */
        //         const id = "blobid" + new Date().getTime();
        //         const blobCache = tinymce.activeEditor.editorUpload.blobCache;
        //         const base64 = reader.result.split(",")[1];
        //         const blobInfo = blobCache.create(id, file, base64);
        //         blobCache.add(blobInfo);
        //         /* call the callback and populate the Title field with the file name */
        //         cb(blobInfo.blobUri(), { title: file.name });
        //       });
        //       reader.readAsDataURL(file);
        //     });

        //     input.click();
        //   },
        //   setup: function (editor) {
        //     editor.on("BeforeSetLink", (e) => {
        //       e.target.rel = "noopener noreferrer";
        //     });
        //     editor.on("init", function (args) {
        //       editor = args.target;

        //       editor.on("NodeChange", function (e) {
        //         if (e && e.element.nodeName.toLowerCase() == "img") {
        //           console.log(e, "AScasklcasckljasckl");
        //         }
        //       });
        //     });
        //     editor.on("focus", () => {
        //       setfocuse(true);
        //     });
        //   },
        // }}
      />
      {/* )} */}
      {/* {(!isReady || keyLoading) && (
        <div>
          <CircularLoader size="1.5rem" />
        </div>
      )} */}
    </div>
  );
}
